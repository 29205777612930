@import "https://cdn.jsdelivr.net/npm/bulma@0.9.3/css/bulma.min.css";

html,
body {
  margin: 0;
  height: 100%;
  overflow-y: auto;
  background-color: #fafafa !important;
}

.full-width {
  width: 100%;
}

.yellow-color {
  background-color: white;
}

.display-flex {
  display: flex !important;
}

.center {
  text-align: center;
}

.red-c {
  color: red;
}

.font-10 {
  font-size: 10px;
}

.font-11 {
  font-size: 11px;
}

.font-13 {
  font-size: 13px;
}

.font-15 {
  font-size: 15px;
}

.font-17 {
  font-size: 17px;
}

.green-c {
  color: #27c527;
}

.icon-size {
  width: 15px !important;
  height: 15px !important;
}

.text-green {
  color: rgba(114, 195, 50, 0.87);
  font-weight: bold;
  font-size: 15px;
}

.text-green-20 {
  color: rgba(114, 195, 50, 0.87);
  font-weight: bold;
  font-size: 20px;
}

.text-blue {
  color: #0063ff;
  font-weight: bold;
  font-size: 15px;
}

.text-align-right {
  text-align: right !important;
  margin: -30px 0 5px 0 !important;
}

.tool-tip-fresh-data {
  background-color: white !important;
  color: #1e5a82 !important;
}

.react-tooltip-detail-for {
  padding: 16px 21px !important;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14),
    0px 3px 3px -2px rgba(0, 0, 0, 0.12) !important;
  width: 477px !important;
}

.react-tooltip-detail-for.show {
  opacity: 1 !important;
}

.__react_component_tooltip {
  pointer-events: auto !important;
}

.dialog-content {
  overflow-y: unset !important;
}

.box-decorate {
  box-shadow: rgb(245, 166, 35) 0px 0px 0px 1px, rgb(245, 166, 35) 0px 0px 1px 1px,
    rgb(245, 166, 35) 1px 1px 1px -1px !important;
}

/* INPUT */
.calc-input > input {
  border: unset !important;
  height: 20px !important;
  box-shadow: unset !important;
  font-size: 12px !important;
  padding: 0 !important;
}

.calc-input > div {
  margin-right: 2px !important;
}

/* INPUT */

.footer {
  color: #eeeeee;
  display: block;
  box-sizing: border-box;
  position: relative;
  padding: 35px;
}

.popup-overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99997;
  overflow: hidden;
  position: fixed;
  background: #fafafa;
  filter: alpha(opacity=80);
  opacity: 1;
}

.popup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  max-width: 800px;
  margin: 0 auto;
}

.popup-content {
  font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99998;
  overflow: hidden;
  position: fixed;
}

.popup-button-close {
  z-index: 99998;
  position: absolute;
  right: 20px;
  top: 15px;
  cursor: pointer;
}

.NavMenu .active {
  color: #f6740e;
}

.BeaconFabButtonFrame {
  display: none !important;
}

.BeaconContainer-enter-done + .BeaconFabButtonFrame {
  display: block !important;
}

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
}

.Maxwidth-450 {
  max-width: 450px !important;
}

.Minwidth-1400 {
  min-width: 1400px !important;
}

.Plan-Footer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 20px 10px 20px;
}

.Plan-Footer > button {
  margin-top: 10px;
  margin-right: 10px;
}

.span-link {
  cursor: pointer;
}

.tabs.is-toggle li.is-active a {
  background-color: #004085;
  border-color: #004085;
}

.button.is-info:not(.is-outlined) {
  background-color: #004085 !important;
}

.has-text-info {
  color: #004085 !important;
}

.button.is-info.is-outlined {
  background-color: transparent;
  border-color: #004085;
  color: #004085;
}

.button.is-info.is-outlined.is-focused,
.button.is-info.is-outlined.is-hovered,
.button.is-info.is-outlined:focus,
.button.is-info.is-outlined:hover {
  background-color: #004085;
  border-color: #004085;
  color: #fff;
}

.limited-trial-content > p,
.limited-trial-content > label {
  margin-bottom: 8px;
}

.react-tel-input .form-control {
  width: 100%;
  margin-top: 4px;
}

.note {
  font-size: 12px;
  text-align: justify;
  padding: 0.75em;
}

.is-trial,
.trial-not-sp {
  text-align: center;
}

.is-trial h1,
.trial-not-sp h1 {
  font-size: 17px;
}

.is-trial > *,
.trial-not-sp > * {
  margin-bottom: 8px;
}

.is-trial > div,
.trial-not-sp > div {
  display: flex;
  justify-content: center;
}

.is-trial .start {
  margin-right: 8px;
  color: #4caf50;
  font-weight: 500;
}

.is-trial .end {
  margin-left: 8px;
  color: #f44336;
  font-weight: 500;
}

.trial-not-sp .end {
  color: #f44336;
  font-weight: 500;
}

.not-yet-trial,
.end-trial {
  display: flex;
  justify-content: center;
}

.not-yet-trial > h1,
.end-trial > h1 {
  max-width: 800px;
  margin-bottom: 8px;
  font-size: 17px;
  text-align: center;
}

.note-az-plans {
  font-size: 14px;
  text-align: center;
}

.connected-text {
  color: #14af27;
}

.az-alert-container {
  display: block;
  text-align: center;
}

.az-alert-download-wrapper {
  margin-top: 24px;
  display: flex;
  justify-content: center;
}

.az-alert-download-wrapper > div {
  padding: 10px;
  max-width: 200px;
  background-color: #fff;
}

.az-alert-download-wrapper .download-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.az-alert-download-wrapper .download-icon > a {
  max-width: 150px;
  margin-right: 10px;
  margin-top: 5px;
  display: inline-flex;
}

.az-alert-download-wrapper .download-icon > a > img {
  max-width: 100%;
}

.hidden {
  display: none !important;
}

.white-href {
  cursor: pointer;
  color: #fff !important;
  text-decoration: underline;
}

.flex-center-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.list-checkbox {
  display: grid !important;
  grid-template-columns: 2fr 2fr 2fr 2fr;
  column-gap: 10px;
  row-gap: 15px;
}
