.zg-track-product {
  height: 100%;
  margin: 20px 20px;
  overflow: auto;
}
.zg-track-product form#trackProduct .ant-col-xs-12 .ant-col {
  padding: 0 0.5em;
}

.zg-track-product form#trackProduct .ant-col-xs-12 & > .ant-row > .ant-col-24 {
  padding: 0 1em; 
}

.zg-track-product form#trackProduct .ant-col-xs-12 .ant-col-xs-24 {
  padding: 0 0.5em 0.5em;
}